import React, { useState } from "react";
import Navbar from "components/Navbar/navbar";
import CallToAction from "components/Call-to-action/call-to-action";
import Footer from "components/Footer/footer";
import AboutIntro from "components/About-intro";
import MinimalArea from "components/Minimal-Area/minimal-area";
import DarkTheme from "layouts/Dark";
import FullTestimonials from "components/Full-testimonials/full-testimonials";
import ParticalsOverlay from "components/Particals-Overlay/ParticalsOverlay";
import SEO from "components/seo/seo";
import LoadingScreen from "components/Loading-Screen";

const About = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);
  const fixedHeader = React.useRef(null);
  const MainContent = React.useRef(null);

  const [theme, setTheme] = useState("Dark");

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });

    var storage = localStorage.getItem("theme");

    if (storage && storage != "") {
      let bodyEl = document.querySelector("body");
      bodyEl.classList.add("themeL");
      setTheme(storage);
    }

    setTimeout(() => {
      if (fixedHeader.current) {
        var slidHeight = fixedHeader.current.offsetHeight;
      }
      if (MainContent.current) {
        MainContent.current.style.marginTop = slidHeight + "px";
      }
    }, 1000);
  }, [fixedHeader, navbarRef]);

  const themeModeChange = (themeMode) => {
    let bodyEl = document.querySelector("body");
    if (themeMode == "Dark") {
      setTheme("themeL");
      bodyEl.classList.add("themeL");
      localStorage.setItem("theme", "themeL");
    } else {
      setTheme("Dark");
      bodyEl.classList.remove("themeL");
      localStorage.removeItem("theme");
    }
  };

  return (
    <DarkTheme>
      <LoadingScreen/>
      <Navbar
        nr={navbarRef}
        lr={logoRef}
        from="about-dark"
        theme={theme}
        themeModeChange={themeModeChange}
      />
      {/* <PagesHeader /> */}
      <header
        ref={fixedHeader}
        className="works-header fixed-slider hfixd valign sub-bg"
      >
        <ParticalsOverlay />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-9 static">
              <div className="capt mt-50">
                <div className="parlx text-center">
                  <h1 className="color-font">About</h1>
                  <p>
                    Our dedicated team of creatives is bursting with talent,
                    experience and passion for what we do.
                  </p>
                  <a
                    href="/contact"
                    className="butn bord curve mt-30 banner-cta"
                  >
                    Let's connect
                  </a>
                </div>

                <div className="bactxt custom-font valign">
                  <span className="full-width banner-bg-large-text">About</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div ref={MainContent} className="main-content">
        <AboutIntro />
        <MinimalArea />

        <FullTestimonials />

        <CallToAction />
        <Footer />
      </div>
    </DarkTheme>
  );
};

export const Head = () => {
  const liveUrl = typeof window !== "undefined" ? window.location.href : "";

  return (
    <>
      <SEO
        canonical={liveUrl}
        title="About Evolvan | Mobile App Design & Web Development Company"
        description="Evolvan, an acclaimed Mobile App Design & Web Development Company, offers a consultative approach and ensures timely project delivery, earning recognition for excellence."
      />
    </>
  );
};

export default About;
