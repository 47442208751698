import { useStaticQuery, graphql } from "gatsby";

export const GetTestimonialData = () => {
  const data = useStaticQuery(graphql`
    query {
      wpgraphql {
        testimonials {
          edges {
            node {
              clientName
              clientTestimonial
              altText
              clientImage {
                guid
              }
              clientDesignation
            }
          }
        }
      }
    }
  `);
  return data.wpgraphql.testimonials.edges;
};
