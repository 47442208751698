import React from "react";
import cardMouseEffect from "common/cardMouseEffect";
import { thumparallaxDown } from "common/thumparallax";
import { StaticImage } from "gatsby-plugin-image";

const MinimalArea = () => {
  React.useEffect(() => {
    cardMouseEffect(document.querySelectorAll(".feat .items"));
    setTimeout(() => {
      thumparallaxDown();
    }, 1000);
  }, []);

  return (
    <>
      <section className="min-area sub-bg revSec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6"></div>
            <div className="col-lg-12 valign ">
              <div className="content pl-0">
                <h4 className="wow color-font">
                  Revolutionizing Businesses with Excellence & Expertise
                </h4>
                <ul id="revSec" className="feat">
                  <li
                    className="wow fadeInUp about-item-box"
                    data-wow-delay=".2s"
                  >
                    <h6>Evolvan Expertise</h6>
                    <p className="pl-0">
                      At Evolvan, we're all about harnessing the potential of
                      Evolvan development. Our team specializes in creating
                      innovative web solutions that are as unique as your
                      business.
                    </p>
                  </li>
                  <li
                    className="wow fadeInUp about-item-box"
                    data-wow-delay=".4s"
                  >
                    <h6>Strategic Digital Marketing</h6>
                    <p className="pl-0">
                      Digital marketing is at the core of what we do. Our
                      experts craft tailored strategies to boost your online
                      presence, connect with your audience, and drive real
                      results.
                    </p>
                  </li>
                  <li
                    className="wow fadeInUp about-item-box"
                    data-wow-delay=".6s"
                  >
                    <h6>Innovative Solutions</h6>
                    <p className="pl-0">
                      We believe in pushing the boundaries of possibility. From
                      design to development, our solutions are as creative as
                      they are functional.
                    </p>
                  </li>
                  <li
                    className="wow fadeInUp about-item-box"
                    data-wow-delay=".6s"
                  >
                    <h6>Client-Centric Approach</h6>
                    <p className="pl-0">
                      Your success is our success. We pride ourselves on being
                      client-centric, working closely with you to bring your
                      vision to life.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="line right"></div>

      <section className="min-area sub-bg about-us">
        <div className="container">
          <div className="col-lg-12 valign">
            <div className="content pt-0 d-flex flex-wrap">
              <div className="col-lg-4 valign">
                <h4 className="wow color-font">About us.</h4>
              </div>

              <div className="col-lg-8">
                <p className="wow txt" data-splitting>
                  At Evolvan Solutions, we believe that technology is the key to
                  driving business success. We are constantly exploring new
                  technologies and approaches to help our clients stay ahead of
                  the curve. We also work closely with our clients to understand
                  their specific needs and develop customized solutions that
                  meet their requirements.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4">
              <div className="img">
                <StaticImage loading ="lazy"
                  className="thumparallax-down"
                  src="../../images/About-us.png"
                  alt="about"
                />
              </div>
            </div>
            <div className="col-lg-8 valign">
              <div className="content pt-0">
                <ul id="about-us" className="feat">
                  <li className="wow fadeInUp" data-wow-delay=".2s">
                    <h5>Why choose Evolvan ?</h5>
                    <br />
                    <h6>Innovation</h6>
                    <p>
                      We pride ourselves on staying ahead of industry trends and
                      leveraging cutting-edge technology.
                    </p>
                  </li>
                  <li className="wow fadeInUp" data-wow-delay=".4s">
                    <h6>Quality</h6>
                    <p>
                      We maintain the highest standards in all our services and
                      products, ensuring optimal performance and reliability.
                    </p>
                  </li>
                  <li className="wow fadeInUp" data-wow-delay=".6s">
                    <h6>Customer-Centric Approach</h6>
                    <p>
                      Your satisfaction is our priority. We're dedicated to
                      understanding your needs and providing tailored solutions
                    </p>
                  </li>
                  <li className="wow fadeInUp" data-wow-delay=".6s">
                    <h6>Expert Team</h6>
                    <p>
                      Our team keeps the requirements of our clients on priority
                      & the development process transparent.{" "}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MinimalArea;
